<prt-header class="z-10000 w-screen"></prt-header>
<div class="relative flex-1 flex overflow-hidden">
  <div class="pt-3.5 pb-20 flex-1 overflow-y-scroll root-scrolling-container relative"
       #rootScrolling>
    <prt-app-loader *ngIf="!isIframe && userService.isLoadingUserAccount && !userService.isShowingLogoutDialog"></prt-app-loader>
    <router-outlet *ngIf="!isIframe">
      <p-toast styleClass="!z-10010"
               position="top-center"></p-toast>
      <p-toast styleClass="!z-10010"
               position="top-center"
               key="unescaped">
        <ng-template let-message
                     pTemplate="message">
          <span [class]="'p-toast-message-icon pi' + (message.icon ? ' ' + message.icon : '')"
                [ngClass]="{ 'pi-info-circle': message.severity == 'info', 'pi-exclamation-triangle': message.severity == 'warn', 'pi-times-circle': message.severity == 'error', 'pi-check': message.severity == 'success' }"></span>
          <div class="p-toast-message-text">
            <div class="p-toast-summary"
                 [innerHTML]="message.summary"></div>
            <div class="p-toast-detail"
                 [innerHTML]="message.detail"></div>
          </div>
        </ng-template>

      </p-toast>
    </router-outlet>
    <p-sidebar [visible]="!!(layoutService.activeSidebarComponent$ | async)"
               (visibleChange)="!$event && layoutService.hideSidebarComponent()"
               position="right"
               [modal]=false
               [style]="{top: '4rem', overflow: 'auto', height: 'calc(100% - 4rem)'}"
               styleClass="prt-sidebar {{layoutService.activeSidebarComponentRef?.instance?.size}}">
      <prt-sidebar></prt-sidebar>
    </p-sidebar>
    <p-scrollTop target="parent"
                 [style]="{'position': 'fixed'}"></p-scrollTop>
  </div>
</div>
<prt-account-error *ngIf="userService.isShowingLogoutDialog"></prt-account-error>
<prt-terms-conditions-dialog *ngIf="userService.showTermsDialog"></prt-terms-conditions-dialog>