export class RouteConfig {
  constructor(
    public display: string,
    public url: string,
    public order: number,
    public isExternalLink = false,
    public styleClass?: string,
  ) {
  }
}
