import { Component, OnInit, ViewChild } from '@angular/core';
import { ReplaySubject, combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SubFormHostDirective } from 'src/app/shared/directives/sub-form-host.directive';
import { LayoutService } from '../layout.service';
import { ISidebarComponent } from './sidebar-component.interface';

@Component({
  selector: 'prt-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  hostDirective$: ReplaySubject<SubFormHostDirective> = new ReplaySubject(1);
  @ViewChild(SubFormHostDirective, { static: true }) set hostDirective(_hostDirective: SubFormHostDirective) {
    this.hostDirective$.next(_hostDirective);
  }

  constructor(
    public layoutService: LayoutService
  ) { }

  ngOnInit(): void {
    combineLatest([this.layoutService.activeSidebarComponent$, this.hostDirective$])
      .pipe(filter(([x, hostDirective]) => !!hostDirective))
      .subscribe(([x, hostDirective]) => {
        const viewContainerRef = hostDirective.viewContainerRef;
        viewContainerRef?.clear();
        if (!x || !viewContainerRef) {
          this.layoutService.setSidebarComponentReference(null);
          return;
        }
        else {
          let ref = viewContainerRef.createComponent<ISidebarComponent>(x);
          this.layoutService.setSidebarComponentReference(ref);
        }
      });
  }
}
