<p-dialog header="Account Updated" [modal]="true" [visible]="true" [draggable]="false" [baseZIndex]="99999">
  <ng-template pTemplate="content">
    <div>
      <div class="my-5 mr-10">
        Your account has been updated. Please sign in again.
      </div>
      <button pButton class="p-button p-button-sm my-5 flex ml-auto" (click)="finishSignout()">
        <span>Ok</span>
        <span class="ml-2">
          <span *ngIf="counter>=0; else loader">({{counter}})</span>
          <ng-template #loader>
            <i class="pi pi-spin pi-spinner text-sm"></i>
          </ng-template>
        </span> </button>
    </div>
  </ng-template>
</p-dialog>