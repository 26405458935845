import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PortalCustomerConfiguration, PortalCustomerConfigurationFlag } from 'src/app/admin/customers/model/portal-customer-configuration.model';
import { PortalCustomerConfigurationService } from 'src/app/core/services/portal-customer-configuration.service';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagGuard implements CanActivate {

  constructor(
    private router: Router,
    private portalCustomerConfigurationService: PortalCustomerConfigurationService,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.portalCustomerConfigurationService.customerConfiguration$.pipe(
      map(customerConfiguration => {
        const featureFlag = route.data['requiredFeatureFlag'];
        if (this.isFeatureEnabled(customerConfiguration, featureFlag)) {
          return true;
        } else {
          this.router.navigate(['/not-authorized']);
          return false;
        }
      })
    );
  }

  private isFeatureEnabled(customerConfiguration: PortalCustomerConfiguration | null, flag: PortalCustomerConfigurationFlag): boolean {
    return customerConfiguration?.[flag] ?? false;
  }
}
