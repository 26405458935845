import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'prt-terms-conditions-dialog',
  templateUrl: './terms-conditions-dialog.component.html',
  styleUrls: ['./terms-conditions-dialog.component.scss']
})
export class TermsConditionsDialogComponent implements OnInit {

  constructor(private userService: UserService) { }
  termsCheckbox: FormControl<boolean | null> = new FormControl();
  privacyCheckbox: FormControl<boolean | null> = new FormControl();
  termsMessage: string = '';
  dialogHeader: string = '';
  isTermsLinkOpened: boolean = false;
  isPrivacyPolicyLinkOpened: boolean = false;
  showLinkMessage: boolean = false;
  showDialog: boolean = true;
  get acceptDisabled() {
    return !this.termsCheckbox.value
      || !this.privacyCheckbox.value;
  }
  get areLinksOpened() {
    return this.isTermsLinkOpened && this.isPrivacyPolicyLinkOpened;
  }
  ngOnInit(): void {
    this.setupDialogContent();
  }

  accept() {
    if (!this.areLinksOpened) {
      this.showLinkMessage = true;
      return;
    }
    this.userService.acceptTerms().subscribe(() => {
      this.showDialog = false;
      this.showLinkMessage = false;
    });
  }
  onPrivacyLinkOpen() {
    this.isPrivacyPolicyLinkOpened = true;
  }
  onTermsLinkOpen() {
    this.isTermsLinkOpened = true;
  }
  setupDialogContent() {
    this.dialogHeader = 'Terms and Conditions';
    this.termsMessage = 'Please read and accept the Terms and Conditions and Privacy Policy to continue using the Portal.';
    const termsAcceptedDate = this.userService.user?.termsAcceptedDate;
    if (termsAcceptedDate) {
      const updatedHeader = `We have updated our ${this.dialogHeader}`;
      const updatedMessage = 'Because we value you as a customer, we want you to know that we have made updates to our Terms and Conditions, including externally sourced information and documentation generated in Portal.';
      this.dialogHeader = updatedHeader;
      this.termsMessage = updatedMessage;
    }
  }
}
