<p-dialog [header]="dialogHeader"
          [modal]="true"
          [(visible)]="showDialog"
          [draggable]="false"
          [baseZIndex]="99999"
          [closable]="false"
          styleClass="max-w-[25%]">
  <ng-template pTemplate="content">
    <div class="mt-5">
      <div class="mb-3">{{termsMessage}}</div>
      <div class="flex flex-col gap-1">
        <div>
          <p-checkbox [formControl]="termsCheckbox"
                      [binary]="true">
          </p-checkbox>
          <span>
            * I Agree and accept the
            <a href="/terms-conditions"
               target="_blank"
               (click)="onTermsLinkOpen()"> <span class="text-blue-500 font-bold">Terms and Conditions</span></a>
          </span>
        </div>
        <div>
          <p-checkbox [formControl]="privacyCheckbox"
                      [binary]="true"></p-checkbox>
          <span>
            * I Agree and accept the
            <a href="/privacy-policy"
               target="_blank"
               (click)="onPrivacyLinkOpen()">
              <span class="text-blue-500 font-bold"> Privacy Policy</span></a>
          </span>
        </div>
      </div>
      <div class="my-5 mr-10">We encourage you to review the terms that apply to you.
      </div>
      <div *ngIf="showLinkMessage"
           class="my-5 mr-10"
           [class.text-red-500]="showLinkMessage">
        <div *ngIf="!isTermsLinkOpened"> * Please click on the terms and conditions link.</div>
        <div *ngIf="!isPrivacyPolicyLinkOpened"> * Please click on the privacy policy link.</div>
      </div>
      <button pButton
              [ngClass]="{'cursor-not-allowed p-button-secondary': acceptDisabled}"
              [disabled]="acceptDisabled"
              class="p-button p-button-sm my-5 flex ml-auto"
              (click)="accept()">
        <span>Accept</span>
      </button>
    </div>
  </ng-template>
</p-dialog>