<div class="px-36 py-16">
  <div class="privacy-page-section">
    <h1 class="underline">Privacy Notice – Customer Portal User Registration Form </h1>
    <p>
      This privacy notice tells you about the information we collect from you when you sign up to our customer portal. In collecting this information, we are acting as a data controller and, by law, we are required to
      provide
      you with information about us, about why and how we use your data, and about the rights you have over your data.
    </p>
    <hr />
  </div>
  <div class="privacy-page-section">
    <h2>
      Who are we?
    </h2>
    <p>
      We are the GAC Group. Our address is Corporate Headquarters, Gulf Agency Company Limited, Jebel Ali, United Arab Emirates. You can contact us by post at the above address or by email at <span
            class="text-blue-800 underline">gdpr&#64;gac.com </span>. We are not
      required to have a data protection officer, so any enquiries about our use of your personal data should be addressed to the contact details above.
    </p>
  </div>
  <div class="privacy-page-section">
    <h2>
      What personal data do we collect?
    </h2>
    <p>
      When you subscribe to our customer portal, we ask you for your name,email address, company, country, city, address, salutation, title, department, mobile and GAC contact email.
    </p>
  </div>
  <div class="privacy-page-section">
    <h2>
      Why do we collect this information?
    </h2>
    <p>
      We use the information to grant access to services relevant to the subscriber company/jobs .
      We ask for your consent to do this, and you will only continue to have access to our customer portal for as long as you continue to consent.
    </p>
  </div>
  <div class="privacy-page-section">
    <h2>
      What do we do with the information collected?
    </h2>
    <p>
      Your information is stored in our database and is not shared with any third parties. We will not use the information to make any automated decisions that might affect you.
    </p>
  </div>
  <div class="privacy-page-section">
    <h2>
      How long do we keep your information for?
    </h2>
    <p>
      Your information is kept for as long as you continue to subscribe to our customer portal.
    </p>
  </div>
  <div class="privacy-page-section">
    <h2>
      Your rights over your information
    </h2>
    <p>
      By law, you can ask us what information we hold about you, and you can ask us to correct it if it is inaccurate. You can also ask for it to be erased and you can ask for us to give you a copy of the information.
      You can also ask us to stop using your information – the simplest way to do this is to withdraw your consent, which you can do at any time by managing your subscription preferences through the link in our emails or
      by
      emailing, writing or telephoning us using the contact details above.
    </p>
  </div>
  <div class="privacy-page-section">
    <h2>
      Your right to complain
    </h2>
    <p>
      If you have a complaint about our use of your information, you can contact our Group GDPR Coordinator at <span class="text-blue-800 underline">gdpr&#64;gac.com </span>
    </p>
  </div>
</div>
