import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MenuModule } from 'primeng/menu';
import { MessagesModule } from 'primeng/messages';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SkeletonModule } from 'primeng/skeleton';
import { TooltipModule } from 'primeng/tooltip';
import { SharedModule } from '../shared/shared.module';
import { AppLoaderComponent } from './app-loader/app-loader.component';
import { DeliveryNotificationComponent } from './header/delivery-notification/delivery-notification.component';
import { HeaderComponent } from './header/header.component';
import { MapViewSwitcherComponent } from './header/map-view-switcher/map-view-switcher.component';
import { SidebarComponent } from './sidebar/sidebar.component';

@NgModule({
  declarations: [HeaderComponent, MapViewSwitcherComponent, AppLoaderComponent, DeliveryNotificationComponent, SidebarComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    MenuModule,
    MessagesModule,
    BrowserAnimationsModule,
    ButtonModule,
    BadgeModule,
    ReactiveFormsModule,
    InputSwitchModule,
    TooltipModule,
    ProgressSpinnerModule,
    SkeletonModule
  ],
  exports: [HeaderComponent, SidebarComponent, AppLoaderComponent]
})
export class LayoutModule { }
