import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { User } from '../../core/models/user.model';
import { UserService } from '../../core/services/user.service';


@Injectable({
  providedIn: 'root'
})
export class LogisticsUserGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.userService.userProfile$
      .pipe(
        filter(u => u !== null),
        map((user: User | null) => {
          if (!!user && user.isLogisticsCustomer) {
            return true;
          }
          this.router.navigateByUrl('/unauthorized');
          return false;
        })
      );
  }
}
