import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from '../../services/authorization.service';

@Component({
  selector: 'prt-account-error',
  templateUrl: './account-error.component.html',
  styleUrls: ['./account-error.component.scss']
})
export class AccountErrorComponent implements OnInit {
  counter = 5;
  constructor(
    private authService: AuthorizationService
  ) { }

  ngOnInit(): void {
    let interval = setInterval(() => {
      if (this.counter <= 0) {
        this.finishSignout();
        clearInterval(interval);
      }
      this.counter--;
    }, 1000);
  }

  finishSignout() {
    this.authService.signOut();
  }

}
