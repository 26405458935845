

import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';

import { environment } from '../environments/environment';

export const b2cAuthorityDomain = environment.b2cSettings.authorityDomain;
// this checks if the app is running on IE
export const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

/**
 * Enter here the user flows and custom policies for your B2C application,
 * To learn more about user flows, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
  names: {
    signUpSignIn: 'B2C_1A_PortalSignIn',
    forgotPassword: 'B2C_1A_ProfileEditPasswordChange',
    editProfile: 'B2C_1A_ProfileEdit'
  },
  authorities: {
    signUpSignIn: {
      authority: `https://${b2cAuthorityDomain}/${environment.b2cSettings.b2cOAuthDomain}/B2C_1A_PortalSignIn`
    },
    forgotPassword: {
      authority: `https://${b2cAuthorityDomain}/${environment.b2cSettings.b2cOAuthDomain}/B2C_1A_ProfileEditPasswordChange`,
    },
    editProfile: {
      authority: `https://${b2cAuthorityDomain}/${environment.b2cSettings.b2cOAuthDomain}/B2C_1A_ProfileEdit`
    }
  },
  authorityDomain: b2cAuthorityDomain
};

/**
* Enter here the coordinates of your Web API and scopes for access token request
* The current application coordinates were pre-registered in a B2C tenant.
*/
export const apiConfig: { scopes: string[]; uri: string; } = {
  scopes: [environment.b2cSettings.portalApiScope],
  uri: `${environment.orderServiceApiUrl}`
};

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.b2cSettings.portalAppClientId,
      authority: b2cPolicies.authorities.signUpSignIn.authority,
      redirectUri: environment.baseUrl,
      postLogoutRedirectUri: environment.baseUrl,
      knownAuthorities: [b2cPolicies.authorityDomain]
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(apiConfig.uri, apiConfig.scopes);
  protectedResourceMap.set(environment.mdmApiUrl, apiConfig.scopes);
  protectedResourceMap.set(environment.documentsServiceUrl, apiConfig.scopes);
  protectedResourceMap.set(environment.portalApiUrl, apiConfig.scopes);
  protectedResourceMap.set(environment.vesselLocationsUrl, apiConfig.scopes);
  protectedResourceMap.set(environment.vesselServiceApiUrl, apiConfig.scopes);
  protectedResourceMap.set(environment.gacTrackDocumentUrl, apiConfig.scopes);
  protectedResourceMap.set(environment.tariffsApiUrl, apiConfig.scopes);
  protectedResourceMap.set(environment.crmServiceApiUrl, apiConfig.scopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...apiConfig.scopes],
    },
  };
}
