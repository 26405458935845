<prt-error-message header="You are unauthorized to access this page."
                   [subHeader]="subHeader"
                   [imageUrl]="'assets/images/401unauthorized.svg'"></prt-error-message>
<ng-template #subHeader>
    <p>Please contact <a class="underline"
           href="mailto: help@gac.com">support</a>
        <span *ngIf="user?.gacContactEmail"> or your <a class="underline"
               href="{{'mailto: ' +  user?.gacContactEmail}}">GAC Contact ({{user?.gacContactEmail}})</a> </span>
        to fix your access.
    </p>
</ng-template>