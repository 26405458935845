import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { User } from '../../core/models/user.model';
import { UserService } from '../../core/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard  {
  constructor(private userService: UserService) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.userService.userProfile$
      .pipe(
        filter(u => u !== null),
        map((user: User | null) => !!user && user.isAdmin)
      );
  }
}
