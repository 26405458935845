import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ErrorMessageComponent } from 'src/app/shared/components/errors/error-message/error-message.component';
import { AppSettings } from '../../models/app-settings.model';
import { FeatureFlagService } from '../../services/feature-flag.service';

@Component({
  selector: 'prt-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss'],
  standalone: true,
  imports: [CommonModule, ErrorMessageComponent]
})
export class MaintenanceComponent implements OnInit, OnDestroy {
  messageObservable$?: Observable<AppSettings | null> = this.featureFlagService.featureFlag$;
  intervalRef: any;
  imageUrl = '/assets/images/503maintenance.svg';
  constructor(private readonly featureFlagService: FeatureFlagService, private readonly router: Router) { }
  ngOnInit() {
    this.intervalRef = setInterval(() => {
      this.messageObservable$?.subscribe((settings) => {
        if (!settings?.isError && !settings?.isMaintenanceMode) {
          this.router.navigate(['/']);
        }
      });
    }, 15000);
  }

  ngOnDestroy() {
    clearInterval(this.intervalRef);
  }

}
