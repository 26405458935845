import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FeatureFlagService } from 'src/app/core/services/feature-flag.service';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceGuard  {
  constructor(
    private featureFlagService: FeatureFlagService,
    private router: Router
  ) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.featureFlagService.featureFlag$.pipe(
      map(settings => {
        return settings?.isMaintenanceMode ? this.router.createUrlTree(['/maintenance']) : true;
      })
    );
  }
}