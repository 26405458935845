import { Injectable } from '@angular/core';
import { Calendar } from 'primeng/calendar';
import { DomHandler } from 'primeng/dom';
import { OverlayPanel } from 'primeng/overlaypanel';
@Injectable({
  providedIn: 'root'
})
export class PrimeNgOverridesService {

  constructor(
  ) {
  }

  public init() {
    this.overrideAlignOverlay();
  }

  // We added this to overcome the issue of absolutely positioned overlays (calendar, overlayPanel...) not being positioned properly in case when appendTo is provided.
  // In this case vendor implementation does not accomodate for possible top offset of scroll container akka appendTo element.
  overrideAlignOverlay() {
    Calendar.prototype.alignOverlay = function () {
      if (this.touchUI) {
        this.enableModality(this.overlay);
      }
      else if (this.appendTo && this.appendTo !== 'body')
        absolutePositionOverride(this.overlay, this.inputfieldViewChild?.nativeElement, this.appendTo);
      else if (this.appendTo) {
        DomHandler.absolutePosition(this.overlay, this.inputfieldViewChild?.nativeElement);
      }
      else
        DomHandler.relativePosition(this.overlay, this.inputfieldViewChild?.nativeElement);
    };

    OverlayPanel.prototype.align = function () {
      if (this.autoZIndex) {
        this.container!.style.zIndex = String(this.baseZIndex + (++DomHandler.zindex));
      }
      if (this.appendTo !== 'body') {
        absolutePositionOverride(this.container, this.target, this.appendTo);
      }
      else {
        DomHandler.absolutePosition(this.container, this.target);
      }
      const containerOffset = DomHandler.getOffset(this.container);
      const targetOffset = DomHandler.getOffset(this.target);
      let arrowLeft = 0;
      if (containerOffset.left < targetOffset.left) {
        arrowLeft = targetOffset.left - containerOffset.left;
      }
      this.container!.style.setProperty('--overlayArrowLeft', `${arrowLeft}px`);
      if (containerOffset.top < targetOffset.top) {
        DomHandler.addClass(this.container, 'p-overlaypanel-flipped');
      }
    };

    let absolutePositionOverride = function (element: any, target: any, appendTo: any) {
      let elementDimensions = element.offsetParent ? { width: element.offsetWidth, height: element.offsetHeight } : DomHandler.getHiddenElementDimensions(element);
      let elementOuterHeight = elementDimensions.height;
      let elementOuterWidth = elementDimensions.width;
      let targetOuterHeight = target.offsetHeight;
      let targetOuterWidth = target.offsetWidth;
      let targetOffset = target.getBoundingClientRect();
      let appendToOffset = appendTo.getBoundingClientRect();
      let windowScrollTop = DomHandler.getWindowScrollTop();
      let windowScrollLeft = DomHandler.getWindowScrollLeft();
      let viewport = DomHandler.getViewport();
      let top, left;
      if (targetOffset.top + targetOuterHeight + elementOuterHeight > viewport.height) {
        top = targetOffset.top + appendTo.scrollTop + windowScrollTop - elementOuterHeight - appendToOffset.top;
        element.style.transformOrigin = 'top';
        if (top < appendTo.scrollTop) {
          top = appendTo.scrollTop;
        }
      }
      else {
        top = targetOuterHeight + targetOffset.top + appendTo.scrollTop - appendToOffset.top;
        element.style.transformOrigin = 'top';
      }
      if (targetOffset.left + elementOuterWidth > viewport.width)
        left = Math.max(0, targetOffset.left + windowScrollLeft + targetOuterWidth - elementOuterWidth - appendToOffset.left);
      else
        left = targetOffset.left + windowScrollLeft - appendToOffset.left;
      element.style.top = top + 'px';
      element.style.left = left + 'px';
    };
  }
}
