import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'prt-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss'],
  imports: [CommonModule],
  standalone: true
})
export class ErrorMessageComponent implements OnInit, OnDestroy {

  @Input() header: string = '';
  @Input() subHeader?: TemplateRef<any>;
  @Input() imageUrl: string = '/assets/images/login-failed.svg';
  constructor(private readonly router: Router) { }
  ngOnDestroy(): void {
  }
  ngOnInit(): void {
  }

  home() {
    this.router.navigate(['']);
  }
}
