import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { User } from 'src/app/core/models/user.model';
import { UserService } from 'src/app/core/services/user.service';
import { ErrorMessageComponent } from '../error-message/error-message.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'prt-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
  standalone: true,
  imports: [CommonModule, ErrorMessageComponent]

})
export class UnauthorizedComponent implements OnInit, OnDestroy {
  private readonly _destroying$ = new Subject<void>();
  user: User | null = null;
  constructor(private readonly userProfileService: UserService) { }
  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }
  ngOnInit(): void {
    this.userProfileService.userProfile$.subscribe(user => this.user = user);
  }
}
