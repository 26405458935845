<div class="px-36 py-16">
  <div class="mb-5">
    <h1 class="underline"> Terms & Conditions – Customer Portal</h1>
    <p class="block mb-3">
      This GAC Customer Portal (‘the Portal’) is provided by the GAC Group. These following terms and conditions apply to the use of the information offered on the GAC Customer Portal. In these terms and conditions,
      “Subscriber” means an individual subscriber and ‘Authorised User’ means an employee duly authorised by an organisation which is a Subscriber or any other person expressly authorised by the GAC Customer Portal.
      “Unauthorised Person” means any person who is neither a Subscriber nor an Authorised User.
    </p>
    <p class="block mb-3">
      The GAC Customer Portal may suspend access by any person to the Service at any time without compensation if it has reasonable grounds to suspect a breach of these terms and conditions by that person.
    </p>
    <p class="block mb-3">
      Your access to and use of the GAC Customer Portal is conditioned on your acceptance of and compliance with these terms and conditions.
    </p>
    <div class="mt-3">
      <hr />
    </div>
  </div>
  <div>
    <h2 class="text-xl">
      Restrictions on use:
    </h2>
    <div class="terms-section">
      <h4>
        Authorised Users and Subscribers may not:
      </h4>
      <ul class="list-decimal list-inside">
        <li>
          Make multiple printouts or copies of information and or materials for distribution to Unauthorised Persons.
        </li>
        <li>
          Re-sell the Portal or any part of the information and or materials to others.
        </li>
        <li>
          Make the Portal or any information and or materials available to Unauthorised Persons on a local area network, a wide area network or on any intranet or extranet.
        </li>
        <li>
          Abstract, download, store, reproduce, transmit, display, copy, distribute or use the information and or materials other than as permitted by paragraph 1 above.
        </li>
      </ul>
    </div>
    <div class="terms-section">
      <h4>
        Passwords and security
      </h4>
      <ul class="list-disc list-inside">
        <li>
          If passwords are issued by the GAC Customer Portal to access the Service.
        </li>
        <li>
          No password may be made available to any Unauthorised Person for the purpose of using the Portal.
        </li>
        <li>
          If GAC Customer Portal suspects that a password is being used by an Unauthorised Person the password maybe cancelled.
        </li>
      </ul>
    </div>
    <div class="terms-section">
      <h4>
        Content
      </h4>
      <ul class="list-disc list-inside">
        <li>GAC Portal contains information and documentation that is externally sourced from third parties, as well as information provided by GAC. Externally sourced information and internally generated information is
          often compiled to form information for the benefit of our customers.</li>
        <li>GAC accepts no liability for any inaccuracies within any of the information or documentation provided within or derived from the GAC Portal, including, but not limited to, information or documentation
          relating to ports, vessels, timings and costings, amongst other matters.</li>
        <li>Whilst GAC strives to ensure the quality and relevance of all information, inclusive of third-party content, GAC disclaims any responsibility for errors, omissions, or any harm resulting from a reliance on
          such information located or derived from the GAC Portal.</li>
        <li>Information and or materials may be added to and removed from the Portal without notice.</li>
        <li>The information and materials contained in the Portal are provided for reference purposes only. They are not intended as a substitute for professional advice or judgement. No information or materials should
          be relied upon to provide legal or other advice with respect to circumstances.</li>
        <li>Every effort is made to keep the information and materials up-to-date, but Subscribers and Authorized Users should obtain verification before relying on any piece of information and or materials in
          circumstances where loss or damage may result.</li>
      </ul>
    </div>
    <div class="terms-section">
      <h4>
        Intellectual PR
      </h4>
      <ul class="list-disc list-inside">
        <li>
          All intellectual property rights and other rights in the GAC Customer Portal (in both machine readable and printed form) belong to the GAC Customer Portal or their third party licensors. Authorised Users and
          Subscribers acquire no proprietary interest in the Portal or information and or materials and except as expressly permitted by these terms and conditions and may not use the Portal or the information and or
          materials in any way that infringes the intellectual property rights in them.
        </li>
        <li>
          Authorised Users and Subscribers may not obscure or remove any copyright notices that appear on information and or materials printed from the Portal.
        </li>
      </ul>
    </div>
    <div class="terms-section">
      <h4>
        Limitation of liablity
      </h4>
      <ul class="list-disc list-inside">
        <li>
          We do not make any representation or warranty that your access to the GAC Customer Portal will be uninterrupted, timely or secure.
          The only obligation of the GAC Customer Portal in respect of interruptions caused by circumstances outside its control shall be to use all reasonable efforts to have the Service reinstated
        </li>
      </ul>
    </div>
    <div class="terms-section">
      <h4>
        Law and jurisdiction
      </h4>
      <ul class="list-disc list-inside">
        <li>
          These terms and conditions shall be interpreted in accordance with the Laws of England and any dispute shall be subject to the exclusive jurisdiction of the English Courts.
        </li>
      </ul>
    </div>
  </div>
</div>