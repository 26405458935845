import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DeliveryRequestCartService } from 'src/app/delivery-requests/delivery-request-cart/delivery-request-cart.service';

@Component({
  selector: 'prt-delivery-notification',
  templateUrl: './delivery-notification.component.html',
  styleUrls: ['./delivery-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class DeliveryNotificationComponent implements OnInit {

  notificationCount = 0;

  constructor(
    public deliveryRequestCartService: DeliveryRequestCartService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.deliveryRequestCartService.cart$.subscribe(
      x => {
        this.notificationCount = x.length;
        this.changeDetector.detectChanges();
      }
    );
  }

  toggleDeliveryCart() {
    this.deliveryRequestCartService.toggleCart();
  }

}
