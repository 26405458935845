<div class="md:m-20 p-5">
    <div class="flex flex-col items-center flex-wrap gap-10 w-4/5 m-auto maintenance-box rounded shadow-lg md:h-[550px] h-[350px]">
        <img *ngIf="imageUrl"
             class="md:h-auto md:w-1/2"
             alt="feature icon"
             [src]="imageUrl">
        <div class="flex flex-col items-center gap-5">
            <div>
                <h1 class="md:text-3xl text-center">{{header}}</h1>
                <ng-template [ngTemplateOutlet]="subHeader"
                             *ngIf="subHeader"></ng-template>
            </div>

            <button class="p-button"
                    label="Home"
                    (click)="home()">Home</button>
        </div>
    </div>
</div>