import { CommonModule } from '@angular/common';
import { provideHttpClient, withJsonpSupport } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { SidebarModule } from 'primeng/sidebar';
import { AccountErrorComponent } from './components/account-error/account-error.component';
import { PrivacyNoticeComponent } from './components/privacy-notice/privacy-notice.component';
import { TermsConditionsDialogComponent } from './components/terms-conditions-dialog/terms-conditions-dialog.component';
import { TermsConditionsComponent } from './components/terms-conditions/terms-conditions.component';


@NgModule({
    declarations: [
        PrivacyNoticeComponent,
        TermsConditionsComponent,
        AccountErrorComponent,
        TermsConditionsDialogComponent
    ],
    exports: [
        AccountErrorComponent,
        TermsConditionsDialogComponent,
        SidebarModule
    ],
    imports: [
        CommonModule,
        DialogModule,
        SidebarModule,
        CheckboxModule,
        ReactiveFormsModule,
    ],
    providers: [provideHttpClient(withJsonpSupport())]
})
export class CoreModule { }
