import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { PortalCustomerConfigurationFlag } from './admin/customers/model/portal-customer-configuration.model';
import { MaintenanceComponent } from './core/components/maintenance/maintenance.component';
import { PrivacyNoticeComponent } from './core/components/privacy-notice/privacy-notice.component';
import { TermsConditionsComponent } from './core/components/terms-conditions/terms-conditions.component';
import { NotfoundComponent } from './shared/components/errors/notfound/notfound.component';
import { UnauthorizedComponent } from './shared/components/errors/unauthorized/unauthorized.component';
import { canActivateMaintenance } from './shared/functions';
import { AdminGuard } from './shared/guards/admin.guard';
import { DashboardGuard } from './shared/guards/dashboard.guard';
import { FeatureFlagGuard } from './shared/guards/feature-flag.guard';
import { LogisticsUserGuard } from './shared/guards/logistics-user.guard';
import { MaintenanceGuard } from './shared/guards/maintenance.guard';
import { RoleGuard } from './shared/guards/role.guard';
import { LoginFailedComponent } from './shared/components/errors/login-failed/login-failed.component';

export interface PortalRoute extends Route {
  children?: PortalRoute[];
  data?: {
    requiredFeatureFlag?: PortalCustomerConfigurationFlag;
    [key: string]: any;
  };
}

const routes: PortalRoute[] = [
  {
    path: '', canActivate: [MsalGuard], children: [
      {
        path: 'dashboard', canActivate: [DashboardGuard, MaintenanceGuard], loadChildren: () => import('./dashboard/dashboard.module').then(m =>
          m.DashboardModule)
      },
      {
        path: 'vessel-calls', canActivate: [RoleGuard, MaintenanceGuard], loadChildren: () => import('./vessel-call/vessel-call.module').then(m =>
          m.VesselCallsModule)
      },
      {
        path: 'shipments', canActivate: [LogisticsUserGuard, MaintenanceGuard], loadChildren: () => import('./shipments/shipments.module').then(m =>
          m.ShipmentsModule)
      },
      {
        path: 'purchase-orders', canActivate: [LogisticsUserGuard, MaintenanceGuard, FeatureFlagGuard],
        data: { requiredFeatureFlag: 'isPurchaseOrderVisible' },
        loadChildren: () => import('./purchase-orders/purchase-orders.module').then(m =>
          m.PurchaseOrdersModule)
      },
      {
        path: 'delivery-requests', canActivate: [LogisticsUserGuard, MaintenanceGuard], loadChildren: () => import('./delivery-requests/delivery-requests.module').then(m =>
          m.DeliveryRequestsModule)
      },
      {
        path: 'pct', canActivate: [RoleGuard, MaintenanceGuard], loadChildren: () => import('./port-cost-template/port-cost-template.module').then(m => m.PortCostTemplateModule)
      },
      {
        path: 'report-list', canActivate: [MaintenanceGuard], loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule)
      },
      {
        path: 'report', canActivate: [MaintenanceGuard], loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule)
      },
      {
        path: 'hot-port-news', canActivate: [MaintenanceGuard], loadChildren: () => import('./hot-port-news/hot-port-news.module').then(m => m.HotPortNewsModule)
      },
      { path: 'fleets', canActivate: [MaintenanceGuard], loadChildren: () => import('./fleet/fleet.module').then(m => m.FleetModule) },
      { path: 'documents', canActivate: [RoleGuard, MaintenanceGuard], loadChildren: () => import('./split-fda-documents/split-fda-documents.module').then(m => m.SplitFdaDocumentsModule) },
      {
        path: 'port-line-up', canActivate: [RoleGuard, MaintenanceGuard], loadChildren: () => import('./port-line-up/port-line-up.module').then(m => m.PortLineUpModule)
      },
      {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full'
      },
    ]
  },
  {
    path: 'admin', canActivate: [MsalGuard, AdminGuard], loadChildren: () => import('./admin/admin.module').then(m =>
      m.AdminModule)
  },
  { path: 'maintenance', component: MaintenanceComponent, canActivate: [canActivateMaintenance] },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'login-failed', component: LoginFailedComponent },
  { path: 'privacy-policy', component: PrivacyNoticeComponent },
  { path: 'terms-conditions', component: TermsConditionsComponent },
  { path: 'hot-port-news', loadChildren: () => import('./hot-port-news/hot-port-news.module').then(m => m.HotPortNewsModule) },
  { path: 'fleets', loadChildren: () => import('./fleet/fleet.module').then(m => m.FleetModule) },
  { path: '**', component: NotfoundComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
