import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PortalCustomerConfigurationService } from 'src/app/core/services/portal-customer-configuration.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardGuard  {
  constructor(
    private portalCustomerConfigurationService: PortalCustomerConfigurationService,
    private router: Router
  ) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.portalCustomerConfigurationService.customerConfiguration$.pipe(
      map(result => {
        if (result?.isDashboardEnabled) {
          return true;
        }
        this.router.navigateByUrl('/vessel-calls');
        return false;
      })
    );
  }
}