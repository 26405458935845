import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class ClaimsInterceptor implements HttpInterceptor {

  constructor(private userService: UserService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if (environment.production || request.url.indexOf(`https://localhost`) === -1
      || !(this.userService.user)) {
      return next.handle(request);
    }

    return next.handle(request.clone({
      headers: request.headers
        .set('Prt-Comp-Code', this.userService.user.customerCode || '')
        .set('Prt-Comp-Name', this.userService.user.customerName || '')
        .set('Prt-Email', this.userService.user.email || '')
    }));
  }
}
