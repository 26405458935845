<nav class="shadow-sm pr-3 relative">
  <div class="prt-container">
    <div class="flex items-center justify-between h-16">
      <!-- logo -->
      <a class="flex items-center flex-none"
         [routerLink]="['/']">
        <img src="assets/images/gacLogoWhite.svg"
             alt="GAC logo" />
        <div class="flex-shrink-0 font-bold text-xl ml-2 block ">
          <span>Portal</span>
        </div>
      </a>
      <!-- links -->
      <div class="flex-1">
        <div class="ml-3 lg:ml-5 xl:ml-7 mr-2 hidden lg:flex items-center space-x-1 xl:space-x-2 text-blue-100"
             *ngIf="!isMaintenanceMode">
          <ng-container *ngFor="let r of routeConfigs">
            <a *ngIf="!r.isExternalLink"
               [routerLink]='r.url'
               class="nav-item hover:text-white px-2 py-2 rounded-md text-xs-plus xl:text-sm font-medium self-center text-center"
               [ngClass]="r.styleClass"
               routerLinkActive="active text-white">{{r.display}}</a>

            <a *ngIf="r.isExternalLink"
               [href]='r.url'
               target="_blank"
               [ngClass]="r.styleClass"
               class="nav-item hover:text-white px-2 py-2 rounded-md  text-xs-plus xl:text-sm font-medium">{{r.display}}</a>
          </ng-container>
        </div>
      </div>
      <!-- utilities -->
      <div class="flex-none ml-1 md:ml-2 xl:ml-3">
        <prt-map-view-switcher *ngIf="(layoutService.showMapViewSwitcher$ | async) else switcherPlaceholder"></prt-map-view-switcher>
        <ng-template #switcherPlaceholder>
          <div *ngIf="(userProfile$ | async)?.isShippingCustomer"
               class="w-[140px]"></div>
        </ng-template>
        <prt-delivery-notification *ngIf="(portalCustomerConfiguration$ | async)?.canRequestDelivery && (layoutService.showDeliveryCartIcon$ | async)"></prt-delivery-notification>
      </div>
      <!-- user area -->
      <div class="hidden lg-plus:flex flex-1 flex-grow-0 flex-shrink items-center ml-1 md:ml-2 xl:ml-3">
        <div class="ml-3 relative flex flex-row items-center "
             *ngIf="(userProfile$ | async)">
          <div class="max-w-xs bg-gray-700 rounded-full flex items-center text-sm">
            <div class="h-9 w-9 rounded-full avatar"
                 [style.backgroundImage]="'url(assets/images/dummyAvatar.png)'"></div>
          </div>
          <span class="ml-3 text-sm xl:text-base leading-4 xl:leading-5 ">{{(userProfile$ | async)?.name}}</span>
          <button pButton
                  class="ml-3 p-button-rounded  bg-transparent border-none flex-none"
                  icon="pi pi-chevron-down"
                  (click)="userMenu.toggle($event)">
          </button>
        </div>
        <p-menu #userMenu
                [model]="userMenuItems"
                styleClass="!mt-3 !z-10010"
                [popup]="true"
                appendTo='body'></p-menu>
      </div>
      <!-- Mobile menu button -->
      <div class="-mr-2 flex lg-plus:hidden"
           *ngIf="(userProfile$ | async)">
        <button pButton
                class="bg-transparent border-none p-2 rounded-md ml-3"
                (click)="mobileMenuOpen=!mobileMenuOpen">
          <span class="sr-only">Open main menu</span>
          <svg [ngClass]="!mobileMenuOpen?'block':'hidden'"
               class="h-6 w-6"
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               viewBox="0 0 24 24"
               stroke="currentColor"
               aria-hidden="true">
            <path stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6h16M4 12h16M4 18h16" />
          </svg>
          <svg [ngClass]="mobileMenuOpen?'block':'hidden'"
               class="h-6 w-6"
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               viewBox="0 0 24 24"
               stroke="currentColor"
               aria-hidden="true">
            <path stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
    </div>
  </div>

  <!-- mobile menu -->
  <div *ngIf="mobileMenuOpen && (userProfile$ | async)"
       (click)="closeMobileMenu()"
       (keydown.enter)="closeMobileMenu()"
       class="flex lg-plus:hidden flex-col absolute w-full mobile-nav pb-7 pt-2 px-4 justify-between items-start border-t-2">
    <!-- links -->
    <div class="flex lg:hidden flex-col items-baseline text-gray-200 border-b py-5 space-y-1"
         *ngIf="!isMaintenanceMode">
      <ng-container *ngFor="let r of routeConfigs">

        <a *ngIf="!r.isExternalLink"
           [routerLink]='r.url'
           class="nav-item  ml-0 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
           routerLinkActive="active text-white">{{r.display}}</a>

        <a *ngIf="r.isExternalLink"
           target="_blank"
           [href]='r.url'
           class="nav-item  ml-0 hover:text-white px-3 py-2 rounded-md text-sm font-medium">{{r.display}}</a>
      </ng-container>
    </div>
    <!-- Profile -->
    <div class="flex w-full mt-4 flex-col">
      <div class="flex justify-between w-full  flex-row-reverse pr-5">
        <div class="ml-3 relative flex flex-row items-center w-full">
          <div class="max-w-xs bg-gray-700 rounded-full flex items-center text-sm">
            <div class="h-9 w-9 rounded-full avatar"
                 [style.backgroundImage]="'url(assets/images/dummyAvatar.png)'"></div>
          </div>
          <span class="ml-3 ">{{(userProfile$ | async)?.name}}</span>
        </div>
      </div>
      <p-menu #userMenu
              [model]="userMenuItems"
              styleClass="!mt-3 !z-10010 bg-transparent border-none mobile-p-menu"></p-menu>
    </div>
  </div>
</nav>

<p-messages severity="info"
            [closable]="true"
            *ngIf="showMaintenanceBanner && !isMaintenanceMode"
            styleClass="md:w-1/2 mx-auto my-0">
  <ng-template pTemplate>
    <div>{{maintenanceBannerMessage}}</div>
  </ng-template>
</p-messages>