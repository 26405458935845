<button class="flex pl-5 pr-1 relative p-button-rounded bg-transparent border-none"
        pButton
        (click)="toggleDeliveryCart()">
  <p-badge severity="danger"
           [value]="(deliveryRequestCartService.isCartLoading$ | async) ? '↻' :
          deliveryRequestCartService.serverError ? '!': (notificationCount?.toString() || '')"
           class="absolute left-0 flex"
           styleClass="min-w-[1.5rem] h-6 leading-6 ml-1"
           [class.loading]="(deliveryRequestCartService.isCartLoading$ | async)"></p-badge>
  <svg xmlns="http://www.w3.org/2000/svg"
       xmlns:xlink="http://www.w3.org/1999/xlink"
       version="1.1"
       width="32"
       height="32"
       viewBox="0 0 24 24">
    <path fill="#ffffff"
          d="M18 18.5C18.83 18.5 19.5 17.83 19.5 17C19.5 16.17 18.83 15.5 18 15.5C17.17 15.5 16.5 16.17 16.5 17C16.5 17.83 17.17 18.5 18 18.5M19.5 9.5H17V12H21.46L19.5 9.5M6 18.5C6.83 18.5 7.5 17.83 7.5 17C7.5 16.17 6.83 15.5 6 15.5C5.17 15.5 4.5 16.17 4.5 17C4.5 17.83 5.17 18.5 6 18.5M20 8L23 12V17H21C21 18.66 19.66 20 18 20C16.34 20 15 18.66 15 17H9C9 18.66 7.66 20 6 20C4.34 20 3 18.66 3 17H1V6C1 4.89 1.89 4 3 4H17V8H20M3 6V15H3.76C4.31 14.39 5.11 14 6 14C6.89 14 7.69 14.39 8.24 15H15V6H3M10 7L13.5 10.5L10 14V11.5H5V9.5H10V7Z" />
  </svg>
</button>