import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { MessageService } from 'primeng/api';
import { LayoutService } from 'src/app/layout/layout.service';
import { environment } from 'src/environments/environment';
import { AuthorizationService } from './core/services/authorization.service';
import { CacheService } from './core/services/cache.service';
import { PrimeNgOverridesService } from './core/services/primeng-overrides.service';
import { ThemeService } from './core/services/theme.service';
import { UserService } from './core/services/user.service';

@Component({
  selector: 'prt-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MessageService]
})
export class AppComponent implements OnInit {
  title = 'portal';
  isIframe: boolean = false;
  @ViewChild('rootScrolling')
  set rootScroling(x: ElementRef) {
    if (x) {
      this.layoutService.setRootScrollingReference(x);
    }
  }
  constructor(
    private authService: AuthorizationService,
    public userService: UserService,
    private themeService: ThemeService,
    private router: Router,
    public layoutService: LayoutService,
    private primeNgOverridesService: PrimeNgOverridesService,
    private cacheService: CacheService) {
  }

  ngOnInit(): void {
    this.primeNgOverridesService.init();

    this.isIframe = window !== window.parent && !window.opener;
    this.authService.applyAuthorizationHandlers();
    this.themeService.init();
    this.cacheService.init();
    let angularPlugin = new AngularPlugin();
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsightsInstrumentationKey,
        extensions: [angularPlugin],
        extensionConfig: {
          [angularPlugin.identifier]: { router: this.router }
        }
      }
    });
    appInsights.loadAppInsights();
  }
}
