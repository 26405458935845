import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class GacApiMgmtInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    let newRequest: HttpRequest<unknown>;

    let subKey = environment.apiManagementSubscriptionKey;

    if (request.url.indexOf(`${environment.mdmApiUrl}`) !== -1) {
      subKey = environment.mdmapiManagementSubscriptionKey;
    }

    if (request.url.indexOf(`${environment.gsApiUrl}`) !== -1) {
      subKey = environment.gsApiSubscriptionKey;
    }

    if (request.url.indexOf(`${environment.gacTrackDocumentUrl}`) !== -1) {
      subKey = environment.gacTrackDocumentSubscriptionKey;
    }

    if (request.url.indexOf(`${environment.crmServiceApiUrl}`) !== -1) {
      subKey = environment.crmServiceSubscriptionKey;
    }

    if (environment.vesselServiceSubscriptionKey && request.url.indexOf(`${environment.vesselServiceApiUrl}`) !== -1) {
      subKey = environment.vesselServiceSubscriptionKey;
    }

    if (request.url.indexOf(`${environment.hotPortNewsUrl}`) !== -1) {
      return next.handle(request);
    }

    newRequest = request.clone({
      headers: request.headers.set('Ocp-Apim-Subscription-Key', subKey)
    });
    return next.handle(newRequest);
  }
}
