import { LayoutService } from './../../layout.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'prt-map-view-switcher',
  templateUrl: './map-view-switcher.component.html',
  styleUrls: ['./map-view-switcher.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MapViewSwitcherComponent implements OnInit {
  private readonly _destroying$ = new Subject<void>();
  isMapViewForm: UntypedFormGroup = this.formBuilder.group({ isMapView: false });
  get isMapViewControl(): AbstractControl | null {
    return this.isMapViewForm.get('isMapView');
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private layoutService: LayoutService
  ) {
    this.isMapViewControl?.valueChanges
      .pipe(
        takeUntil(this._destroying$)
      ).subscribe(v => {
        this.layoutService.toggleMapViewSwitcher(true, v);
      });
    this.layoutService.showMap$
      .pipe(
        takeUntil(this._destroying$)
      ).subscribe(x => {
        if (x !== this.isMapViewControl?.value) {
          this.isMapViewControl?.setValue(x);
        }
      });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }

}
