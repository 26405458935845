import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MsalModule } from '@azure/msal-angular';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { ScrollTopModule } from 'primeng/scrolltop';
import { ToastModule } from 'primeng/toast';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { MSALProviders, PortalHttpInterceptors } from './core/http-interceptors';
import { LayoutModule } from './layout/layout.module';
@NgModule({
    declarations: [
        AppComponent
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        CoreModule,
        MsalModule,
        LayoutModule,
        ToastModule,
        ScrollTopModule
    ],
    providers: [
        MSALProviders,
        PortalHttpInterceptors,
        {
            provide: ErrorHandler,
            useClass: ApplicationinsightsAngularpluginErrorService
        },
        provideHttpClient(withInterceptorsFromDi())
    ]
})
export class AppModule { }
